import { AuthConfig } from '@auth0/auth0-angular';

const authConfig: AuthConfig = {
  domain: 'axle-qa.us.auth0.com',
  clientId: 'befbZUualvhInUHDkgA88AGUAxGkewMQ',
  authorizationParams: {
    redirect_uri: window.location.origin
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: 'https://axle-qa.herokuapp.com/api/v1/private*'
      }
    ]
  },
  skipRedirectCallback: window.location.pathname === '/preferences'
};

export const environment = {
  env: 'qa',
  production: false,
  herokuLink: 'https://axle-qa.herokuapp.com/api/v1/private',
  herokuLinkPublic: 'https://axle-qa.herokuapp.com/api/v1/public',
  auth0: authConfig
};
